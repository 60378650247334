<template>
  <div class="ccr">
    <div class="text-center" v-if="role !== 'MSDH'">
      <h3>Consumer Confidence Report Data</h3>
      <p>CCR is being prepared. Please check back later this week.</p>
    </div>
    <div v-if="role == 'MSDH'">
      <h3>Consumer Confidence Report Data</h3>
      <div v-if="component_message">
        <b-alert show :variant="component_message.msgtype">
          <span v-if="component_message.msgtype == 'warning'">
            &#9888;
          </span>
          <span>
            {{ component_message.message }}
          </span>
        </b-alert>
      </div>
      <p>
        Select a county and PWS for a list of files required to complete your CCR. <strong>If your system purchases some
          or all of its water from another system</strong>, you must also obtain the sellers results for your CCR. Once
        complete, upload your CCR using the <em>CCR Uploads</em> tab in this application.
      </p>
      <a href="/static/CCR_Certification.pdf" target="_blank">
        CCR Certification Form
      </a> - Click to download if you need a CCR Certification Form
      <b-form class="mt-3">
        <b-form-group label="County" label-for="county">
          <b-form-select id="county" @change="onChangeCounty()" v-model="county"
            :options="county_options"></b-form-select>
        </b-form-group>
        <b-form-group v-if="county" label="Public Water System" label-for="pws">
          <b-form-select id="pws" @change="fetchCCRFileList()" v-model="pws" :options="pws_options"></b-form-select>
        </b-form-group>
        <b-form-group v-if="pws" label="Year" label-for="ccr-year">
          <b-form-select id="ccr-year" @change="fetchCCRFileList()" v-model="ccr_year"
            :options="year_options"></b-form-select>
        </b-form-group>

      </b-form>
      <div v-if="pws && ccr_file_list.length == 0">
        No data found.
      </div>
      <b-modal @shown="onShowPDFModal" hide-header ok-only ok-title="Done" id="modal-pdf" size="xl" title="View PDF">
        <div id="mypdf" class="w-100" style="height: 80vh;"></div>
      </b-modal>
      <div v-if="this.pws && ccr_file_list.length > 0" class="mt-4">
        <p>Select a file to view or download or
          <a :href="api_url + '/zipped-ccr-files?year=' + ccr_year + '&pws_id=' + pws_id">download all files in a single
            Zip archive</a>.
          <!--
        <a :href="'/ccr_data/' + ccr_year + '/' + pws_id + '/' + pws_id + '_' + ccr_year + 'CCR.zip'">download all files in a single Zip archive</a>.
        -->
        </p>
        <b-table-lite responsive :items="ccr_file_list" :fields="table_fields">
          <template #cell(download)="data">
            <a :href="'/ccr_data/' + ccr_year + '/' + pws_id + '/' + data.item.filename" download>
              <b-button size="sm" variant="primary">
                <b-icon-download></b-icon-download> &nbsp;
              </b-button>
            </a>
          </template>
          <template #cell(view)="data">
            <b-button size="sm" variant="primary" @click="showPDF(pws_id, ccr_year, data.item.filename)">View</b-button>
          </template>
          <template #cell(filename)="data">
            <a :href="'/ccr_data/' + ccr_year + '/' + pws_id + '/' + data.item.filename" target="_blank">{{ data.value
              }}</a>
          </template>
        </b-table-lite>
      </div>
      
      <div class="mt-5">
        <h4>Additional Guidance</h4>
        <ul class="list-unstyled">
          <li><a href="/static/ccrdocs/Guidance_Acronyms-Defintions.pdf" target="_blank">Acronyms and Definitions</a></li>
          <li><a href="/static/ccrdocs/Guidance_Appendix-A-To-Subpart-O-Regulated-Contaminants.pdf" target="_blank">Appendix A to Subpart O Regulated Contaminants</a></li>
          <li><a href="/static/ccrdocs/Guidance_EPA-Best-Practices-Factsheet.pdf" target="_blank">EPA Best Practices Factsheet</a></li>
          <li><a href="/static/ccrdocs/Guidance_EPA_Converting-Lab-Units.pdf" target="_blank">EPA Converting Lab Units</a></li>
          <li><a href="/static/ccrdocs/Guidance_EPA_Factsheet-UCMR5.pdf" target="_blank">EPA Factsheet UCMR5</a></li>
          <li><a href="/static/ccrdocs/Guidance_EPA_Quick-Reference-Guide.pdf" target="_blank">EPA Quick Reference Guide</a></li>
          <li><a href="/static/ccrdocs/Guidance_EPA_Understanding-Your-CCR.pdf" target="_blank">EPA Understanding Your CCR</a></li>
          <li><a href="/static/ccrdocs/Guidance_FAQ.pdf" target="_blank">Frequently Asked Questions</a></li>
          <li><a href="/static/ccrdocs/Guidance_Reporting-Your-Data.pdf" target="_blank">Reporting Your Data</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import PDFObject from 'pdfobject'

export default {
  name: "CCReportData",
  data() {
    return {
      user_agent: navigator.userAgent,
      component_message: null,
      county: null,
      pws: null,
      pws_data: null,
      pdf_href: null,
      ccr_file_list: [],
      year_options: [],
      ccr_upload_file: null,
      ccr_upload_file_name: '',
      upload_success_alert: false,
      upload_failure_alert: false,
      api_url: process.env.VUE_APP_API_URI,
      ccr_year: new Date().getFullYear() - 1,
      county_options: [
        { text: "Select a County", value: null },
        { text: "01-ADAMS", value: "ADAMS" },
        { text: "02-ALCORN", value: "ALCORN" },
        { text: "03-AMITE", value: "AMITE" },
        { text: "04-ATTALA", value: "ATTALA" },
        { text: "05-BENTON", value: "BENTON" },
        { text: "06-BOLIVAR", value: "BOLIVAR" },
        { text: "07-CALHOUN", value: "CALHOUN" },
        { text: "08-CARROLL", value: "CARROLL" },
        { text: "09-CHICKASAW", value: "CHICKASAW" },
        { text: "10-CHOCTAW", value: "CHOCTAW" },
        { text: "11-CLAIBORNE", value: "CLAIBORNE" },
        { text: "12-CLARKE", value: "CLARKE" },
        { text: "13-CLAY", value: "CLAY" },
        { text: "14-COAHOMA", value: "COAHOMA" },
        { text: "15-COPIAH", value: "COPIAH" },
        { text: "16-COVINGTON", value: "COVINGTON" },
        { text: "17-DESOTO", value: "DESOTO" },
        { text: "18-FORREST", value: "FORREST" },
        { text: "19-FRANKLIN", value: "FRANKLIN" },
        { text: "20-GEORGE", value: "GEORGE" },
        { text: "21-GREENE", value: "GREENE" },
        { text: "22-GRENADA", value: "GRENADA" },
        { text: "23-HANCOCK", value: "HANCOCK" },
        { text: "24-HARRISON", value: "HARRISON" },
        { text: "25-HINDS", value: "HINDS" },
        { text: "26-HOLMES", value: "HOLMES" },
        { text: "27-HUMPHREYS", value: "HUMPHREYS" },
        { text: "28-ISSAQUENA", value: "ISSAQUENA" },
        { text: "29-ITAWAMBA", value: "ITAWAMBA" },
        { text: "30-JACKSON", value: "JACKSON" },
        { text: "31-JASPER", value: "JASPER" },
        { text: "32-JEFFERSON", value: "JEFFERSON" },
        { text: "33-JEFF DAVIS", value: "JEFF DAVIS" },
        { text: "34-JONES", value: "JONES" },
        { text: "35-KEMPER", value: "KEMPER" },
        { text: "36-LAFAYETTE", value: "LAFAYETTE" },
        { text: "37-LAMAR", value: "LAMAR" },
        { text: "38-LAUDERDALE", value: "LAUDERDALE" },
        { text: "39-LAWRENCE", value: "LAWRENCE" },
        { text: "40-LEAKE", value: "LEAKE" },
        { text: "41-LEE", value: "LEE" },
        { text: "42-LEFLORE", value: "LEFLORE" },
        { text: "43-LINCOLN", value: "LINCOLN" },
        { text: "44-LOWNDES", value: "LOWNDES" },
        { text: "45-MADISON", value: "MADISON" },
        { text: "46-MARION", value: "MARION" },
        { text: "47-MARSHALL", value: "MARSHALL" },
        { text: "48-MONROE", value: "MONROE" },
        { text: "49-MONTGOMERY", value: "MONTGOMERY" },
        { text: "50-NESHOBA", value: "NESHOBA" },
        { text: "51-NEWTON", value: "NEWTON" },
        { text: "52-NOXUBEE", value: "NOXUBEE" },
        { text: "53-OKTIBBEHA", value: "OKTIBBEHA" },
        { text: "54-PANOLA", value: "PANOLA" },
        { text: "55-PEARL RIVER", value: "PEARL RIVER" },
        { text: "56-PERRY", value: "PERRY" },
        { text: "57-PIKE", value: "PIKE" },
        { text: "58-PONTOTOC", value: "PONTOTOC" },
        { text: "59-PRENTISS", value: "PRENTISS" },
        { text: "60-QUITMAN", value: "QUITMAN" },
        { text: "61-RANKIN", value: "RANKIN" },
        { text: "62-SCOTT", value: "SCOTT" },
        { text: "63-SHARKEY", value: "SHARKEY" },
        { text: "64-SIMPSON", value: "SIMPSON" },
        { text: "65-SMITH", value: "SMITH" },
        { text: "66-STONE", value: "STONE" },
        { text: "67-SUNFLOWER", value: "SUNFLOWER" },
        { text: "68-TALLAHATCHIE", value: "TALLAHATCHIE" },
        { text: "69-TATE", value: "TATE" },
        { text: "70-TIPPAH", value: "TIPPAH" },
        { text: "71-TISHOMINGO", value: "TISHOMINGO" },
        { text: "72-TUNICA", value: "TUNICA" },
        { text: "73-UNION", value: "UNION" },
        { text: "74-WALTHALL", value: "WALTHALL" },
        { text: "75-WARREN", value: "WARREN" },
        { text: "76-WASHINGTON", value: "WASHINGTON" },
        { text: "77-WAYNE", value: "WAYNE" },
        { text: "78-WEBSTER", value: "WEBSTER" },
        { text: "79-WILKINSON", value: "WILKINSON" },
        { text: "80-WINSTON", value: "WINSTON" },
        { text: "81-YALOBUSHA", value: "YALOBUSHA" },
        { text: "82-YAZOO", value: "YAZOO" },
      ]
    }
  },
  methods: {
    showPDF(pws_id, ccr_year, filename) {
      //console.log(evt)
      console.log('pws_id', pws_id)
      console.log('filename', filename)
      console.log('ccr_year', ccr_year)
      this.pdf_href = '/ccr_data/' + ccr_year + '/' + pws_id + '/' + filename
      console.log(this.pdf_href)
      this.$bvModal.show('modal-pdf')
    },
    isMobile() {
      if (/Android|iPhone/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    onShowPDFModal(e) {
      console.log(e)
      PDFObject.embed(this.pdf_href, '#mypdf')
    },
    onChangeCounty() {
      this.pws = null
      this.pws_data = null
      this.ccr_file_list = []
      this.pdf_href = null
      this.fetchPWSData()
    },
    async fetchComponentMessage() {
      const path = process.env.VUE_APP_API_URI + "/component-message"
      const res = await this.axios.get(path, { params: { name: "CCR" } })
      if (res.data && res.data.message) {
        console.log('component message', res.data)
        this.component_message = res.data
      } else {
        console.log("no component message")
      }
    },
    fetchPWSData() {
      this.pws = null
      if (!this.county) {
        console.log("County is not set.")
        return
      }
      console.log("called fetchPWSData")
      const path = process.env.VUE_APP_API_URI + "/get-pws-for-county"
      console.log(path)
      return this.axios
        .get(path, { params: { county_name: this.county } })
        .then((res) => {
          console.log(res)
          if (res.data) {
            this.pws_data = res.data
            console.log("got data")
          } else {
            console.log("record data fetch failed")
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    fetchCCRFileList() {
      if (!this.pws_id) {
        console.log("PWS ID is not set.")
        return
      }
      console.log("called fetchCCRFileList")
      const path = process.env.VUE_APP_API_URI + "/list-ccr-files"
      console.log(path)
      return this.axios
        .get(path, { params: { year: this.ccr_year, pws_id: this.pws_id } })
        .then((res) => {
          console.log(res)
          if (res.data) {
            this.ccr_file_list = res.data
            console.log("got data")
          } else {
            console.log("record data fetch failed")
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    onUploadCCRFile() {
      const form_data = new FormData()
      this.ccr_upload_file_name = this.ccr_upload_file.name
      form_data.append('ccr_file', this.ccr_upload_file)
      form_data.append('pws_id', this.pws_id_signed_in)
      this.axios.post(process.env.VUE_APP_API_URI + '/ccr-file', form_data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        console.log(res)
        if (res.data && res.data.msg == 'OK') {
          this.upload_success_alert = true
          this.ccr_upload_file = null
        } else {
          this.upload_failure_alert = true
        }
      }).catch((error) => {
        console.error(error)
        this.upload_failure_alert = true
      })
    }
  },
  mounted() {
    // populated years
    const year_start = 2020
    const year_end = new Date().getFullYear() - 1
    for (var i = year_start; i <= year_end; i++) {
      this.year_options.push(i)
    }
    this.fetchComponentMessage()
  },
  computed: {
    pws_id_signed_in() {
      return this.$store.state.pws_id
    },
    role() {
      return this.$store.state.role
    },
    signed_in() {
      return this.$store.state.signed_in
    },
    table_fields: function () {
      if (this.isMobile()) {
        return ['download', 'filename', 'description']
      }
      else {
        return ['download', 'view', 'filename', 'description']
      }
    },
    pws_options: function () {
      let options = [{ value: null, text: "Select a Water System" }]
      console.log("Computing for:")
      console.log(this.pws_data)
      console.log(this.county)
      if (this.pws_data) {
        for (let obj of this.pws_data) {
          let display = obj.pws_id + " - " + obj.pws_name
          options.push(display)
          console.log(obj)
        }
      }
      console.log(options)
      return options
    },
    pws_id: function () {
      if (this.pws) {
        console.log(this.pws.substring(0, 7))
        return this.pws.substring(0, 7)
      } else {
        return null
      }
    }
  }
}
</script>
